import React from "react"
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom"
import Login from "../pages/Login"
import DashboardLayout from "../layouts/DashboardLayout"
import Dashboard from "../pages/Dashboard"
import CreateMerchant from "../pages/Dashboard/create"

const config = {
    "path": "/",
    "element": <Outlet />,
    "children": [
        {
            path: "/",
            element: <Login />
        },
        {
            path: "/",
            element: <DashboardLayout />,
            children: [
                {
                    path: "/dashboard",
                    element: <Dashboard />
                },
                {
                    path: "/create",
                    element: <CreateMerchant />
                },
                {
                    path: "/edit",
                    element: <CreateMerchant />
                }

            ]
        }
    ]
}
const router = createBrowserRouter([
    config
])

const RouterConfig = () => <RouterProvider router={router} />
export default RouterConfig