import './styles/App.css';
import './styles/theme.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RouterConfig from './routes';
const theme = createTheme()
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <RouterConfig />
      </ThemeProvider>
    </div>
  );
}

export default App;
