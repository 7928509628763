import { Typography } from "@mui/material"

export const Text = ({ label, sx, ...props }) => {
    return (
        <Typography
            {...props}
            sx={{
                fontSize: 14,
                fontFamily: 'var(--poppins-light)',
                ...sx
            }}>
            {label}
        </Typography>
    )
}

export const LightText = ({ label, sx, ...props }) => {
    return (
        <Text
            {...props}
            label={label}
            sx={{
                ...sx,
                fontFamily: 'var(--poppins-light)'
            }}
        />
    )
}
export const NormalText = ({ label, sx, ...props }) => {
    return (
        <Text
            {...props}
            label={label}
            sx={{
                ...sx,
                fontFamily: 'var(--poppins-regular)'
            }}
        />
    )
}

export const MediumText = ({ label, sx, ...props }) => {
    return (
        <Text
            {...props}
            label={label}
            sx={{
                ...sx,
                fontFamily: 'var(--poppins-medium)'
            }}
        />
    )
}

export const BoldText = ({ label, sx, ...props }) => {
    return (
        <Text
            {...props}
            label={label}
            sx={{
                ...sx,
                fontFamily: 'var(--poppins-bold)'
            }}
        />
    )
}
