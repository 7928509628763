import axios from "axios";

export const getAddress = (address_components) => {
    const postal_code = getPlace(address_components, 'postal_code');
    const country = getPlace(address_components, 'country');
    const state = getPlace(address_components, 'administrative_area_level_1')
    const city = getPlace(address_components, 'locality');
    const line1 = [
        "premise",
        "street_address",
        "route",
        "sublocality_level_2",
        "sublocality_level_1"

    ]
    const address_line_1 = getPlaces(address_components, line1)
    const line2 = [
        "administrative_area_level_2",
        "administrative_area_level_3"
    ]
    const address_line_2 = getPlaces(address_components, line2)
    const address = {
        postal_code, country, state, city, address_line_1, address_line_2
    }
    console.log(address)
    return address
}
const getPlaces = (address, labels) => {
    let list = [];
    labels.map(label => {
        const a = getPlace(address, label);
        if (a) {
            list.push(a)
        }

    })
    return list.join(", ")
}
const getPlace = (address, label) => {
    let place = [];
    for (let i = 0; i < address.length; i++) {
        const item = address[i];
        if (item.types.includes(label)) {
            place.push(item.long_name);
            // break;
        }
    }
    return place.join(", ")
}

export const getAddressFromLatLng = async (lat, lng) => {
    const googleKey = "AIzaSyAWtOBi8C72FZlRJcJCrVMJpaU5kgyYINA";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleKey}`
    try {
        const response = await axios.get(url);
        const { data } = response;
        const { results } = data;
        if (results.length > 0) {
            const address = results[0]
            console.log(address)
            return getAddress(address.address_components)
        }
    } catch (e) {
        console.log(e)
        return {}
    }
}
