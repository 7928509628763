import React from "react";

const DownArrowIcon = ({ color = "#787878", ...props }) => {
    return (
        <svg
            width="15"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity="0.4"
                d="M14.9127 6.99992C14.9127 10.6766 11.7154 13.6666 7.78543 13.6666C3.85548 13.6666 0.658203 10.6766 0.658203 6.99992C0.658203 3.32392 3.85548 0.333252 7.78543 0.333252C11.7154 0.333252 14.9127 3.32392 14.9127 6.99992Z"
                fill={color} />
            <path
                d="M10.7937 6.03834C10.7937 6.16567 10.7417 6.29367 10.6376 6.39101L8.16447 8.71567C8.06398 8.80967 7.92785 8.86234 7.7853 8.86234C7.64347 8.86234 7.50734 8.80967 7.40685 8.71567L4.93228 6.39101C4.72416 6.19567 4.72416 5.87967 4.9337 5.68434C5.14324 5.48967 5.48178 5.49034 5.6899 5.68567L7.7853 7.65434L9.88071 5.68567C10.0888 5.49034 10.4267 5.48967 10.6362 5.68434C10.7417 5.78167 10.7937 5.91034 10.7937 6.03834Z"
                fill={color} />
        </svg>

    )
}

export default React.memo(DownArrowIcon)