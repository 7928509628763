import React from "react"
import { Button as MButton } from "@mui/material"
import { Text } from "./Text"
const Button = ({ label, onClick, sx, labelStyle, color = 'var(--primary-btn-bg-color)', ...props }) => {
    return (
        <MButton
            variant="contained"
            onClick={onClick}
            className="button"
            sx={{
                paddingLeft: '5px',
                paddingRight: '5px',
                minWidth: 80,
                textTransform: 'none',
                backgroundColor: sx?.backgroundColor ? sx.backgroundColor : color,
                '&:focus,&:hover': {
                    backgroundColor: sx?.backgroundColor ? sx.backgroundColor : color
                },
                ...sx
            }}
            {...props}
        >
            <Text label={label} style={{ textTransform: 'none', color: 'var(--white)', fontFamily: 'var(--poppins-medium)', ...labelStyle }} />
        </MButton>
    )
}

const CancelButton = React.memo(({ onClick, label = "Cancel" }) => {
    return (
        <Button
            onClick={onClick}
            label={label}
            sx={{ backgroundColor: 'white', marginRight: '15px' }}
            labelStyle={{ color: 'var(--black)' }} />
    )
})
export default Button
export {
    CancelButton
}