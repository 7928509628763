import * as ExcelJS from "exceljs"
import { saveAs } from "file-saver";
const getWookBook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Spinov channel dashboard'
    workbook.created = new Date();
    return workbook;
}
const downloadAs = async (workbook, fileName = "sample.xlsx") => {
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, fileName)
}
const setHeaderStyle = (worksheet, rowNum) => {

    const row = worksheet.getRow(rowNum);
    row.eachCell(function (cell, colNumber) {

        cell.font = {
            color: { argb: '00000000' },
            size: 14,
            bold: true
        };
    });
    row.height = 24;
    row.alignment = { vertical: 'middle' }

}
const downloadMerchants = (data, columns) => {
    const workbook = getWookBook();
    const ws = workbook.addWorksheet("Sheet 1");
    ws.addRow([
        "Merchant Name",
        "Contact person",
        "Email",
        "Location"
    ])
    ws.getColumn(1).width = 40;
    ws.getColumn(2).width = 40;
    ws.getColumn(3).width = 40;
    ws.getColumn(4).width = 100;
    setHeaderStyle(ws, 1);
    data.map((d) => {
        ws.addRow([
            d.merchant_name,
            d.contact_person,
            d.email,
            d.location
        ])
        return null
    })

    downloadAs(workbook, `merchants_${new Date().getTime()}.xlsx`)
}

export {
    downloadMerchants
}
