import { useEffect } from "react";
// import { getAddress, getAddressFromLatLng } from './placeUtils'
import Input from "./Input";
import SearchIcon from "./svg/SearchIcon";
import { getAddress } from '../utils/placeUtils'
const GoogleSearch = ({ onAddressChange }) => {
    // let maps = window.google.maps;
    console.log("navigator.geolocation", navigator.geolocation)
    // const [center, setCenter] = useState({ lat: 40.76, lng: -73.983 })

    useEffect(() => {

        const input = document.getElementById("google_search");

        const searchBox = new window.google.maps.places.SearchBox(input);
        searchBox.addListener("places_changed", () => {

            const places = searchBox.getPlaces();
            if (places.length == 0) {
                return;
            }
            const { geometry, address_components } = places[0];
            const { location } = geometry;
            const latlng = { lat: location.lat(), lng: location.lng() }
            const search_address = input.value;
            const place = search_address.split(",")[0]
            console.log("places", latlng, address_components, input.value)
            let address = getAddress(address_components)
            address["address_line_1"] = place + ", " + address.address_line_1
            address["geo_location"] = {
                latitude: latlng.lat,
                longitude: latlng.lng
            }

            onAddressChange(address)
            // setCenter(latlng)
        }
        )
    }, [])
    return (

        <Input
            id="google_search"
            onChange={(e) => console.log()}
            placeholder="Search location"
            sx={{ paddingRight: '10px' }}
            endAdornment={
                <SearchIcon />
            }
        />

    )
}

export default GoogleSearch