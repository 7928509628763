import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { MediumText } from "../../components/Text";
import Input from "../../components/Input";
import { reducer, initialState, actions } from "./reducer";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { createMerchant, updateMerchant } from "../../services/apis";
import AlertMessage from "../../components/AlertMessage";
import GoogleSearch from "../../components/GoogleSearch";
const countryCodes = [
  {
    value: "001",
    code: "+1",
    country: "USA",
  },
  {
    value: "091",
    code: "+91",
    country: "India",
  },
];
const CreateMerchant = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log("");
  const { setField, setAddressField, setData, setAddress } = actions;
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ open: false, severity: "error" });
  const [newPhoneNumber,setNewPhoneNumber] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const onFieldChange = (field, value) => {
    dispatch(setField(field, value));
    setErrors({ ...errors, [field]: null });
  };
  const handleCountryCodeChange = (event) => {
    const countryCode = event.target.value;
    console.log(countryCode)
    onFieldChange("country_code", countryCode);
  };

  const handlePhoneNumberKeyPress = (event) => {
    // Allow only numeric characters and Backspace/Delete
    const isValidKey = /^[0-9\b]+$/.test(event.key);
    
    if (!isValidKey || state.phone.length >= 11) {
      event.preventDefault();
    }

  };
  useEffect(() => {
    const { state } = location;
    if (state) {
      const data = JSON.parse(state.data);
      setIsEdit(true);
      dispatch(setData(data));
    }
  }, [location, dispatch, setData]);
  const onCancel = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);
  // Defining the onSave function which is executed when the save button is clicked
  console.log(state.country_code, "outline:'none'")

  const onSave = () => {
    // Defining a schema using Yup for input validation that specifies fields that need to be validated, rules for those fields and error messages if validation fails.
    const schema = Yup.object({
      email: Yup.string()
        .required("Please enter email")
        .email("Invalid email address"),
      merchant_name: Yup.string().required("Please enter merchant name"),
      contact_person: Yup.string().required("Please enter contact person"),
      phone: Yup.string()
        .min(10, "Invalid phone number")
        .required("Please enter phone number"),
      address: Yup.object({
        address_line_1: Yup.string().required("Please enter address line 1"),
        city: Yup.string().required("Please enter city"),
        postal_code: Yup.string()
          .min(6, "Invalid postal code")
          .required("Please enter postal code"),
        address_line_2: Yup.string().required("Please enter address line 2"),
        state: Yup.string().required("Please enter state"),
        country: Yup.string().required("Please enter country"),
      }),
    });

    // Creating an object with data from form
    const obj = {
      merchant_name: state.merchant_name,
      contact_person: state.contact_person,
      email: state.email,
      country_code: state.country_code,
      phone: state.phone,
      merchant_logo_url: "",
      status: 1,
      address: {
        address_line_1: state.address.address_line_1,
        address_line_2: state.address.address_line_2,
        postal_code: state.address.postal_code,
        city: state.address.city,
        state: state.address.state,
        country: state.address.country,
        geo_location: state.address.geo_location,
      },
    };
    console.log("server",obj);
    // Validating the object against the schema, and executing logic based on the validation result.
    schema
      .validate(obj, { abortEarly: false })
      .then(() => {
        // If validation passes and the form is in edit mode, update the merchant using the updateMerchant function with an object containing updated merchant data and a callback function to execute after the update is completed.
        if (isEdit) {
          obj["merchant_id"] = state.merchant_id;
          updateMerchant(obj, onEditCallback);
        }
        // If validation passes and the form is not in edit mode, create the merchant using the createMerchant function with an object containing new merchant data and a callback function for when the creation is completed.
        else {
          createMerchant(obj, onSaveCallback);
        }
      })
      // If validation fails, catch the error and set the errors state with an object containing values of each invalid field and corresponding error messages
      .catch((err) => {
        let error = {};
        err.inner.forEach((e) => {
          error = { ...error, [e.path]: e.message };
        });
        setErrors(error);
      });
  };
  const onEditCallback = (status, response) => {
    if (status) {
      // navigate("/dashboard");
      setAlert({
        open: true,
        message: "Merchant updated successfully",
        severity: 'success'
    })
    setTimeout(() => {
        navigate('/dashboard')
    }, 1000)} 
    else {
      setAlert({
        open: true,
        message: "Failed to edit merchant",
        severity: "error",
      });
    }
  };
  const onSaveCallback = (status, response) => {
    if (status) {
      // navigate("/dashboard");
      setAlert({
        open: true,
        message: "Merchant created successfully",
        severity: 'success'
    })
    setTimeout(() => {
        navigate('/dashboard')
    }, 1000)
    }
  };
  // const onFieldChange = (field, value) => {
  //   dispatch(setField(field, value));
  //   setErrors({ ...errors, [field]: null });
  // };


  const onAddressFieldChange = (field, value) => {
    dispatch(setAddressField(field, value));
    let f = `address.${field}`;
    setErrors({ ...errors, [f]: null });
  };
  const onCloseAlert = () => {
    setAlert({ open: false, severity: "error" });
  };
  const onAddressChange = (address) => {
    console.log("onAddressChange....", address);
    dispatch(setAddress(address));
  };
  const handleCountry = (code) => {
    console.log("codeeeee", code);
    // setCountryCode(code);
  };
  return (
    <Grid container justifyContent={"center"}>
      <AlertMessage {...alert} handleClose={onCloseAlert} />
      <Grid sx={{ maxWidth: 900 }}>
        <Grid container className="card">
          <Grid
            container
            className="card-title"
            justifyContent={"space-between"}
          >
            <MediumText label="My Account" sx={{ fontSize: 18 }} />
            <Grid>
              <Button
                onClick={onCancel}
                label={"Cancel"}
                sx={{ backgroundColor: "white", marginRight: "10px" }}
                labelStyle={{ color: "black" }}
              />
              <Button onClick={onSave} label={isEdit ? "Edit" : "Save"} />
            </Grid>
          </Grid>
          <Grid container className="card-body">
            <Grid container sx={{ paddingLeft: "10px" }}>
              <MediumText label="Contact information" sx={{ fontSize: 16 }} />
            </Grid>
            <Grid container>
              <Column>
                <Input
                  value={state.merchant_name}
                  error={errors.merchant_name}
                  placeholder="Merchant name"
                  onChange={(value) => onFieldChange("merchant_name", value)}
                />
              </Column>
              <Column>
                <Input
                  value={state.contact_person}
                  error={errors.contact_person}
                  placeholder="Contact person"
                  onChange={(value) => onFieldChange("contact_person", value)}
                />
              </Column>
            </Grid>
            <Grid container>
              <Column>
                <Input
                  value={state.email}
                  placeholder="Email"
                  error={errors.email}
                  type="email"
                  disabled={isEdit}
                  onChange={(value) => onFieldChange("email", value)}
                />
              </Column>
              <Column>
                {/* <Input
                      value={state.phone}
                      placeholder="Phone number"
                      error={errors.phone}
                      onChange={(value) => onFieldChange('phone', value)}
                                /> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    gap: "4px",
                  }}
                >
                  <select
                    id="countryCode"
                    value={state.country_code}
                    onChange={handleCountryCodeChange}
                    style={{
                      padding: "12px",
                      borderRadius: "12px",
                      backgroundColor: "#F5F5F5",
                      outline:'none'
                    }}
                  >
                    {countryCodes.map((country) => {
                      return (
                        <option value={country.value} key={country.value}>
                          {country.code}
                        </option>
                      );
                    })}
                  </select>

                  <Input
                    id="phoneNumber"
                    value={state.phone}
                    placeholder="Phone number"
                    error={errors.phone}
                    onChange={(value) => onFieldChange("phone", value)}
                    onKeyPress={handlePhoneNumberKeyPress}
                  />
                </div>
              </Column>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="card" sx={{ marginTop: "20px" }}>
          <Grid container className="card-body">
            <Grid container sx={{ paddingLeft: "10px" }}>
              <MediumText label="Address information" sx={{ fontSize: 16 }} />
            </Grid>
            <Grid container sx={{ padding: "10px" }}>
              <Grid item xs={12} md={6}>
                <GoogleSearch onAddressChange={onAddressChange} />
              </Grid>
            </Grid>

            <Grid container>
              <Column>
                <Input
                  value={state.address.address_line_1}
                  placeholder="Address line 1"
                  error={errors["address.address_line_1"]}
                  onChange={(value) =>
                    onAddressFieldChange("address_line_1", value)
                  }
                />
              </Column>
              <Column>
                <Input
                  value={state.address.address_line_2}
                  placeholder="Address line 2"
                  error={errors["address.address_line_2"]}
                  onChange={(value) =>
                    onAddressFieldChange("address_line_2", value)
                  }
                />
              </Column>
            </Grid>

            <Grid container>
              <Column>
                <Input
                  value={state.address.postal_code}
                  placeholder="Postal code"
                  error={errors["address.postal_code"]}
                  onChange={(value) =>
                    onAddressFieldChange("postal_code", value)
                  }
                />
              </Column>
              <Column>
                <Input
                  value={state.address.city}
                  placeholder="City"
                  error={errors["address.city"]}
                  onChange={(value) => onAddressFieldChange("city", value)}
                />
              </Column>
            </Grid>

            <Grid container>
              <Column>
                <Input
                  value={state.address.state}
                  placeholder="State"
                  error={errors["address.state"]}
                  onChange={(value) => onAddressFieldChange("state", value)}
                />
              </Column>
              <Column>
                <Input
                  value={state.address.country}
                  placeholder="Country"
                  error={errors["address.country"]}
                  onChange={(value) => onAddressFieldChange("country", value)}
                />
              </Column>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const Column = ({ children }) => {
  return (
    <Grid xs={12} md={6} sx={{ padding: "10px" }}>
      {children}
    </Grid>
  );
};
export default CreateMerchant;
