import { Grid, Paper, InputAdornment, IconButton } from "@mui/material";
import React, { useState } from "react";
import { LightText, MediumText } from "../../components/Text";
import Input from "../../components/Input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MailIcon from "../../components/svg/MailIcon";
import HideIcon from "../../components/svg/HideIcon";
import Button from "../../components/Button";
import * as Yup from 'yup'
import { loginAction } from "../../services/apis";
import { useNavigate } from "react-router-dom";
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()
    const onChangeEmail = (val) => {
        setEmail(val)
        if (errors.email !== null) {
            setErrors({ ...errors, email: null })
        }

    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const onChangePassword = (val) => {
        setPassword(val)
        if (errors.password !== null)
            setErrors({ ...errors, password: null })
    }
    const handleLogin = () => {
        const schema = Yup.object({
            email: Yup.string().required("Please enter email").email("Invalid email address"),
            password: Yup.string().min(6).required("Please enter password")
        });
        schema.validate({ email, password }, { abortEarly: false })
            .then(() => {
                loginAction({ email, password }, (status) => {
                    if (status) {
                        navigate('/dashboard')
                    }
                })
            })
            .catch(err => {
                let error = {};
                err.inner.forEach(e => {
                    error = { ...error, [e.path]: e.message };

                });
                setErrors(error);
            })
    }

    return (
        <Grid container className="page-bg" sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Paper sx={{ borderRadius: '32px', padding: "24px" }}>
                <Grid
                    container
                    sx={{
                        maxWidth: 275,
                        // height: 480,
                        // padding: "10px",
                        borderRadius: "32px",
                        backgroundColor: "white",
                        alignItems: 'flex-start'
                    }}
                >
                    <Grid container justifyContent={"center"} sx={{ gap: '15px' }} component="form" noValidate
                        autoComplete="off">
                        <Grid container className="logo" sx={{ width: 241, height: 75 }}></Grid>
                        <Grid container justifyContent={"center"} sx={{ marginBottom: '20px' }}>
                            <MediumText label="Welcome Back!" sx={{ color: 'black', fontSize: 28, lineHeight: '42px' }} />
                            <LightText label="Please login your account to continue." sx={{ color: '#787878', lineHeight: '24px', fontSize: 14 }} />

                        </Grid>

                        <Grid container>
                            <Input
                                value={email}
                                placeholder="Email Address"
                                error={errors.email}
                                name="spinov-password"
                                autoComplete="off"
                                onChange={onChangeEmail}
                                endAdornment={
                                    <MailIcon />
                                }
                                sx={{ paddingRight: '10px' }}
                            />
                        </Grid>
                        <Grid container>
                            <Input
                                value={password}
                                type={showPassword ? "text" : "password"}
                                error={errors.password}
                                placeholder="Password"
                                onChange={onChangePassword}
                                endAdornment={<InputAdornment position="end">
                                    <IconButton
                                        sx={{
                                            borderRadius: "4px",
                                            width: 45,
                                            height: 45,
                                        }}
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {
                                            showPassword ? (
                                                <Visibility sx={{ fontSize: 18 }} />
                                            ) : (
                                                <VisibilityOff sx={{ fontSize: 18 }} />
                                            )}
                                    </IconButton>
                                </InputAdornment>}
                                sx={{ paddingRight: '10px' }}
                            />
                        </Grid>
                        <Grid container sx={{ marginTop: '20px' }}>
                            <Button
                                onClick={handleLogin}
                                label="Login" sx={{ width: '100%', height: 50, borderRadius: '16px' }} />
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>

        </Grid>
    )
}

export default Login