import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useMemo } from "react";
import { Text } from "../components/Text";

import { useNavigate } from "react-router-dom";
import DownArrowIcon from "../components/svg/DownArrow";
import LogoutIcon from '../components/svg/LogoutIcon';

// import ProfileView from '../ProfileView';
import ProfileLogo from '../components/svg/Profile';
import { logoutAction } from "../services/apis";
const ProfileDropdown = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event) => {
      console.log("event.currentTarget",event.currentTarget.offsetWidth)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logoutClick = () => {
        handleClose();

        logoutAction((status) => {
            if (status) {
                navigate("/")
            }
        })

    };
    const username = useMemo(() => {
        const user = localStorage.getItem("user")
        if (user) {
            const userObj = JSON.parse(user)
            return userObj.name
        }
        return null
    }, [])
    return (
        <Grid className="Main">

            <Button
                id="dropdown"
                // aria-controls={open ? 'basic-menu' : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="text"
                sx={{
                    textTransform: "none",
                    backgroundColor: "#F8F7F9",
                    padding: '5px',
                    paddingRight: '15px',
                    borderRadius: 50
                }}
            >
                <Grid sx={{
                    borderRadius: 100, backgroundColor: "#F8B19F", width: 38, height: 38,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <ProfileLogo />
                </Grid>

                <Text label={username} sx={{ marginLeft: '5px', paddingRight: '15px', color: "#000000" }} />

                <DownArrowIcon ml={1} />
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{marginTop:'35px'}}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >

                <MenuItem onClick={logoutClick} sx={{width:150,justifyContent:'space-between'}}>

                    <Text label="Logout" sx={{ marginLeft: '10px' }} />
                    <LogoutIcon

                    />
                </MenuItem>
            </Menu>

        </Grid>
    );
};
export default ProfileDropdown;
