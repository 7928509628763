import { Grid, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import {
  deleteMerchant,
  getAllMerchants,
  toggleMerchantStatus,
  demofixtureAction,
} from "../../services/apis";
import MenuIcon from "../../components/svg/MenuIcon";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { downloadMerchants } from "../../utils/excel";
import ConfirmModal from "./ConfirmModal";
import SearchIcon from "../../components/svg/SearchIcon";
import SnackBar from "../../components/Snackbar";
import SportsSelectionPopup from "../../components/sportsSelectionPopup";
const columns = [
  {
    dataKey: "merchant_name",
    label: "Merchant Name",
    width: "20%",
  },
  {
    dataKey: "contact_person",
    label: "Contact Person",
    width: "20%",
  },
  {
    dataKey: "email",
    label: "Email",
    width: "20%",
  },
  {
    dataKey: "location",
    label: "Location",
    width: "26%",
  },
  {
    dataKey: "menu",
    label: "",
    width: "4%",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  console.log("selectedMerchant", selectedMerchant);
  const open = Boolean(anchorEl);
  const [showDelete, toggleDelete] = useState(false);
  const [showDeactivate, toggleDeactivate] = useState(false);
  const [snackbar, setSnackbar] = useState();
  ///
  const [showSportsPopup, setShowSportsPopup] = useState(false);
  const [selectedSport, setSelectedSport] = useState(null);

  const openSportsPopup = () => {
    setShowSportsPopup(true);
  };

  const handleSportSelection = (sport) => {
    // Do something with the selected sport, such as sending it to your API.
    console.log(`Selected sport: ${sport}`);

    // Close the sports selection popup.
    setShowSportsPopup(false);
    setSelectedSport(sport); // Set the selected sport in state if needed.
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMerchant(null);
  };
  // Defining a new function called onSearch using the useCallback hook.
  const onSearch = useCallback(
    (key) => {
      // Checking if `key` parameter is truthy (has a value)
      if (key) {
        // Using the `filter()` method to filter out merchants based on whether their `merchant_name` or `contact_person` property includes the search key
        let m = merchants.filter(
          (e) =>
            e.merchant_name.toLowerCase().includes(key.toLowerCase()) ||
            e.contact_person.toLowerCase().includes(key.toLowerCase())
        );
        // Updating state variable for "rows" with the filtered array of merchants
        setRows(m);
      } else {
        // If `key` parameter is not truthy, set "rows" back to the original array of merchants
        setRows(merchants);
      }
    },
    [merchants]
  );

  const openMenu = (event, merchant) => {
    setAnchorEl(event.currentTarget);
    setSelectedMerchant(merchant);
  };
  // Declaring a function named onEdit that takes in a parameter called selectedMerchant.
  const onEdit = useCallback(
    (selectedMerchant) => {
      // Using the navigate function to navigate to the '/edit' page and pass state data as an object containing selected merchant data.
      navigate("/edit", { state: { data: JSON.stringify(selectedMerchant) } });
    },
    [navigate]
  );

  const download = useCallback(() => {
    downloadMerchants(rows);
  }, [rows]);
  const onDelete = () => {
    toggleDelete(true);
  };
  const onDeactivate = () => {
    toggleDeactivate(true);
  };

  const onDeleteClose = () => {
    toggleDelete(false);
    handleClose();
  };
  const onDeactivateClose = () => {
    toggleDeactivate(false);
    handleClose();
  };
  // Declaring a function called onConfirmDelete.
  const onConfirmDelete = () => {
    // Calling the deleteMerchant function and passing in an object with a key-value pair of merchant_id: selectedMerchant.merchant_id as its first argument, and a callback function as its second argument.
    deleteMerchant({ merchant_id: selectedMerchant.merchant_id }, (status) => {
      // Checking if the status returned from the deleteMerchant function is truthy.
      if (status) {
        // If true, we call toggleDelete function with `false` value to close the delete toggle.
        toggleDelete(false);
        // We then call the getMerchants function to retrieve the updated merchant list.
        getMerchants();
        // And finally, we call the handleClose function to close any modals or dialogues present.
        handleClose();
      }
    });
  };

  // Defining a new function called onConfirmDeactivate
  const onConfirmDeactivate = () => {
    // Calling a function named toggleMerchantStatus and passing in an object with two key-value pairs as its first argument, and a callback function as its second argument.
    toggleMerchantStatus(
      {
        merchant_id: selectedMerchant.merchant_id, // A property set to the merchant ID of the currently selected merchant
        status: !selectedMerchant.status, // A property set to the opposite value of the current status of the selected merchant (to either deactivate or activate it)
      },
      (status) => {
        // Checking if `status` variable is truthy
        if (status) {
          // If true, calling toggleDeactivate function with `false` parameter value to hide the confirmation dialogue
          toggleDeactivate(false);
          // Calling getMerchants function to update the list of merchants
          getMerchants();
          // Finally, calling handleClose function to close any modals or popups present
          handleClose();
        }
      }
    );
  };

  const getMerchants = () => {
    getAllMerchants((status, data) => {
      if (status) {
        const merchants = data.merchants.map((merchant) => {
          const { address } = merchant;
          merchant[
            "location"
          ] = `${address.address_line_1},${address.address_line_2},${address.postal_code},${address.city},${address.state},${address.country}`;
          merchant["menu"] = (
            <span
              onClick={(event) => openMenu(event, merchant)}
              style={{ cursor: "pointer" }}
            >
              <MenuIcon />
            </span>
          );
          return merchant;
        });
        setMerchants(merchants);
        setRows(merchants);
      }
    });
  };

  const onDemo = (merchant) => {
    console.log("merchant", merchant);
    demofixtureAction({ merchant_id: merchant.merchant_id }, (status, res) => {
      if (status) {
        setSnackbar({ message: res.detail, severity: "success" });
      } else {
        setSnackbar({ message: res.detail, severity: "error" });
      }
      // setShowSportsPopup(false);
    });
    handleClose();
  };
  ////

  useEffect(() => {
    getMerchants();
  }, []);
  const create = useCallback(() => {
    navigate("/create");
  }, [navigate]);
  return (
    <Grid container>
      <Controllers create={create} download={download} onSearch={onSearch} />
      <CustomTable columns={columns} rows={rows} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => onEdit(selectedMerchant)}>Edit</MenuItem>
        <MenuItem onClick={onDeactivate}>
          {selectedMerchant?.status ? "Deactivate" : "Activate"}
        </MenuItem>
        <MenuItem onClick={onDelete}>Delete</MenuItem>
        {/* <MenuItem onClick={() =>onDemo(selectedMerchant)}>Start Demo</MenuItem> */}
        <MenuItem>
          <span onClick={openSportsPopup} style={{ cursor: "pointer" }}>
            Start Demo
          </span>
          {showSportsPopup && (
            <SportsSelectionPopup
              open={showSportsPopup}
              onClose={() => setShowSportsPopup(false)}
              merchantId={selectedMerchant?.merchant_id}
              onSelectSport={handleSportSelection}
            />
          )}
        </MenuItem>
      </Menu>
      {showDelete && (
        <ConfirmModal
          open={showDelete}
          onConfirm={onConfirmDelete}
          handleClose={onDeleteClose}
          type="delete"
        />
      )}
      {showDeactivate && (
        <ConfirmModal
          open={showDeactivate}
          onConfirm={onConfirmDeactivate}
          handleClose={onDeactivateClose}
          type={selectedMerchant?.status ? "deactivate" : "activate"}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid="taxeslist_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {showSportsPopup && (
        <SportsSelectionPopup
          open={showSportsPopup}
          onClose={() => setShowSportsPopup(false)}
          merchantId={selectedMerchant?.merchant_id}
          onSelectSport={handleSportSelection}
          setSnackbar={setSnackbar}
        />
      )}
    </Grid>
  );
};
let timer;
const Controllers = React.memo(({ create, download, onSearch }) => {
  const [key, setKey] = useState("");

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      onSearch(key);
    }, 300);
    return () => clearTimeout(timer);
  }, [key, onSearch]);
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        backgroundColor: "white",
        width: "100%",
        borderRadius: "24px",
        padding: "10px",
        marginBottom: "20px",
      }}
    >
      <Grid>
        <Input
          onChange={setKey}
          val={key}
          id="search"
          placeholder="Search merchants"
          sx={{ paddingRight: "10px" }}
          endAdornment={<SearchIcon />}
        />
      </Grid>
      <Grid>
        <Button
          onClick={download}
          label="Download"
          color="white"
          labelStyle={{ color: "black" }}
          sx={{ marginRight: "10px", padding: "5px 15px", height: 40 }}
        />
        <Button
          onClick={create}
          label="New merchant"
          sx={{ marginRight: "10px", padding: "5px 15px", height: 40 }}
        />
      </Grid>
    </Grid>
  );
});
export default Dashboard;
