import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#FFF1ED", justifyContent: 'center' }} {...other}>
            {children}

        </DialogTitle>
    );
}

export {
    BootstrapDialog,
    BootstrapDialogTitle
}
