// SportsSelectionPopup.js
import AxiosInstance from "../services/AxiosInstance";
import React, { useState,useEffect } from "react";
import Snackbar from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Radio
} from "@mui/material";

const SportsSelectionPopup = ({ open, onClose, onSelectSport,merchantId,setSnackbar}) => {

  const sports = [
    { sport: "Soccer", sport_type: "soccer" },
    { sport: "Basketball", sport_type: "basketball"},
    { sport: "American Football", sport_type:"american_football"},
  ];
  const [selectedSport, setSelectedSport] = useState(null);

  const handleSportSelection = (sport) => {
    // setSelectedSport(sport);
    if (!sport.hidden) {
      setSelectedSport(sport);
    }
  };
  ////
  useEffect(() => {
    
  }, []);


  const handleConfirm = () => {
  if (selectedSport) {
    const sport_type = selectedSport.sport_type;

    // Call the "merchant/demo_fixture" API with the selected sport_type and merchant_id
    AxiosInstance.post('merchant/demo_fixture', { merchant_id: merchantId, sport_type })
      .then((response) => {
        // Handle the API response here
        console.log("API response", response.data);
        setSnackbar({message:response.data.detail, severity:"success"});
        onClose();
      })
      .catch((error) => {
        console.error("API error", error);
        setSnackbar({message:error.detail,severity: "error"});
        onClose();
      });
  } else {
    // Handle the case where no sport is selected
    console.error("Please select a sport.");
  }
};

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center", backgroundColor: "#FFF1ED" }}>
        Select Sport
      </DialogTitle>
      <DialogContent>
        <List>
          {sports.map((sport) => (

            <ListItem
              key={sport.sport_type}
              button
              onClick={() => handleSportSelection(sport)}
              disabled={sport.hidden}
            >
              <Radio
                edge="start"
                checked={
                  selectedSport &&
                  selectedSport.sport_type === sport.sport_type
                }
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={sport.sport} />{" "}
            </ListItem>
          ))}
        </List>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            onClick={handleConfirm}
            sx={{
              width: "200px",
              backgroundColor: "#FB6C49",
              color: "white",
              "&:hover": {
                backgroundColor: "#FB6C49",
              },
            }}
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SportsSelectionPopup;
