import { OutlinedInput, FormHelperText } from "@mui/material"

const Input = ({ onChange, value, sx, ...props }) => {
    const onFocus = (e) => {
        const el = e.target;
        el.classList.remove("blur")
    }
    const onBlur = (e) => {
        const el = e.target;
        el.classList.add("blur")
    }
    return (
        <>
            <OutlinedInput
                onFocus={onFocus}
                onBlur={onBlur}
                sx={{
                    width: '100%',
                    height: 45,
                    padding: 0,
                    paddingRight: '3px',
                    paddingLeft: '10px',
                    color: 'var(--black)',
                    backgroundColor: 'var(--input-bg-color)',
                    borderRadius: '14px',
                    fontSize: 14,
                    paddingLeft: '10px',
                    fontFamily: 'var(--poppins-light)',
                    '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover': {
                        borderColor: 'var(--input-border-color)',
                        padding: 0
                        // change the border color here
                    },
                    '& .MuiOutlinedInput-input::placeholder': {
                        color: 'var(--input-placeholder-color)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline,:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--input-border-color) !important',
                        borderWidth: 1,
                        padding: 0
                    },
                    ...sx
                }}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                autoComplete="off"
                className="outlinedInput"
                aria-describedby="outlined-weight-helper-text"

                {...props}
            />
            {
                props.error &&
                <FormHelperText id="component-error-text" sx={{ color: "#C10000", fontFamily: 'var(--poppins-light)' }}>{`* ${props.error}`}</FormHelperText>
            }
        </>
    )
}

export default Input