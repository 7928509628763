
import AxiosInstance from "./AxiosInstance"

const loginAction = async (params, callback) => {
    try {
        const response = await AxiosInstance.post('login', params)
        localStorage.setItem("user", JSON.stringify(response.data))
        callback(true)
    } catch (error) {
        callback(false)
    }

}
const logoutAction = async (callback) => {
    try {
        const response = await AxiosInstance.post('logout')
        localStorage.clear()
        callback(true, response.data)

    } catch (error) {
        callback(false)
    }

}
// action
const demofixtureAction = async (params,callback) => {
    try {
        // const response = await AxiosInstance.post(`merchant/create_fixture?merchant_id=${params}`)
        const response = await AxiosInstance.post('merchant/demo_fixture', params);
        // localStorage.clear()
        callback(true, response.data)

    } catch (error) {
        callback(false,error);
    }

}
const getAllMerchants = async (callback) => {
    try {
        const response = await AxiosInstance.get('merchant');
        callback(true, response.data)
    } catch (error) {
        callback(false, [])
    }
}

const createMerchant = async (params, callback) => {
    try {
        const response = await AxiosInstance.post('merchant', params)
        callback(true, response.data)
    } catch (error) {
        callback(false, error)
    }
}
const updateMerchant = async (params, callback) => {
    try {
        const response = await AxiosInstance.put('merchant', params)
        callback(true, response.data)
    } catch (error) {
        callback(false, error)
    }
}
const deleteMerchant = async (params, callback) => {
    try {
        const response = await AxiosInstance.delete('merchant', { data: params })
        callback(true, response.data)
    } catch (error) {
        callback(false, error)
    }
}
const toggleMerchantStatus = async (params, callback) => {
    try {
        const response = await AxiosInstance.put('merchant/toggle_status', params)
        callback(true, response.data)
    } catch (error) {
        callback(false, error)
    }
}
export {
    loginAction,
    logoutAction,
    demofixtureAction,
    getAllMerchants,
    createMerchant,
    updateMerchant,
    deleteMerchant,
    toggleMerchantStatus
}