import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid
} from '@mui/material'

import { TableVirtuoso } from 'react-virtuoso';

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Grid} {...props} ref={ref} sx={{ borderRadius: '24px' }} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed', minWidth: 600 }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => {
        return (<TableRow {...props} sx={{ minWidth: 600, borderRadius: '24px', backgroundColor: props["data-item-index"] % 2 === 0 ? 'white' : "#FCFBFD" }} />)
    },
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent(columns) {
    return (
        <TableRow>
            {columns.map((column, index) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.numeric || false ? 'right' : 'left'}
                    style={{ width: column.width }}
                    sx={{
                        backgroundColor: '#FFF1ED',
                        borderBottomWidth: 0,
                        fontWeight: 600,
                        borderTopLeftRadius: index === 0 ? '24px' : 0
                    }}
                >
                    {column.label}
                </TableCell>
            ))}
        </TableRow>
    );
}

function rowContent(_index, row, columns) {

    return (
        <React.Fragment>
            {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    align={column.numeric || false ? 'right' : 'left'}
                    sx={{ borderBottomWidth: 0 }}
                >
                    {row[column.dataKey]}
                </TableCell>
            ))}
        </React.Fragment>
    );
}

export default function CustomTable({ columns = [], rows = [], onEndReach }) {
    const height = window.innerHeight - (140 + 48)

    return (
        <Grid style={{ height: height, width: '100%', backgroundColor: 'transparent' }}>
            <TableVirtuoso
                data={rows}
                components={VirtuosoTableComponents}
                fixedHeaderContent={() => fixedHeaderContent(columns)}
                itemContent={(_index, row) => rowContent(_index, row, columns)}
                endReached={onEndReach}
            />
        </Grid>
    );
}