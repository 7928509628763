import React, { useMemo } from "react";

import { BootstrapDialog, BootstrapDialogTitle } from '../../components/BootstrapDialog'
import { MediumText, NormalText } from "../../components/Text";
import { DialogActions, DialogContent, Grid } from "@mui/material";
import Button from "../../components/Button";
const messages = {
    delete: {
        title: "Delete merchant",
        message: "Do you want delete merchant?"
    },
    deactivate: {
        title: "Deactivate merchant",
        message: "Do you want deactivate merchant?"
    },
    activate: {
        title: "Activate merchant",
        message: "Do you want activate merchant?"
    }
}
const Delete = ({ open, onConfirm, handleClose, type }) => {
    const { title, message } = useMemo(() => {
        return messages[type]
    }, [type])
    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" >
                <Grid container justifyContent={"center"}>
                    <MediumText label={title} />
                </Grid>

            </BootstrapDialogTitle>
            <DialogContent >
                <Grid container sx={{ padding: "20px" }}>
                    <NormalText label={message} />
                </Grid>

            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={"space-between"}>
                    <Button onClick={handleClose} label="Close" sx={{ backgroundColor: "white" }} labelStyle={{ color: "black" }} />
                    <Button onClick={onConfirm} label="Confirm" />
                </Grid>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default Delete