import axios from 'axios'
import config from '../../src/config.json'

const AxiosInstance = axios.create({
    baseURL: config.server_url + config.path,
    timeout: 5000,
    headers: {
        'content-type': 'application/json',
        "Accept": "application/json"
    }
})

AxiosInstance.interceptors.response.use(
    (res) => {
        if (res.config.url === 'login') {
            AxiosInstance.defaults.headers.common["token"] = res.data.token
            localStorage.setItem('token', res.data.token)
        }
        return res
    },
    (error) => {
        if (error?.response) {
            const { data } = error.response;

            return Promise.reject(data)
        } else {
            let data = { status: error.message };
            if (data.status.indexOf("timeout") !== -1) {
                data.status = "No internet connection"
            }
            if (data.status.indexOf("Network") !== -1) {
                data.status = "No internet connection"
            }
            return Promise.reject(data)
        }
    }
)
AxiosInstance.interceptors.request.use((req) => {
    try {
        const token = localStorage.getItem('token')

        if (token !== '') {

            AxiosInstance.defaults.headers.common["token"] = token
            req.headers['token'] = token;
        }
        return req

    } catch (e) {
    }
    finally {
        return req
    }

})

export default AxiosInstance