const initialState = {
    merchant_name: "",
    contact_person: "",
    email: "",
    phone: "",
    country_code:"091",
    address: {
        country: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        state: "",
        postal_code: "",
        geo_location:{
            latitude:0,
            longitude:0
        }
    }
}
const SET_FIELD = "SET_FIELD"
const SET_ADDRESS_FIELD = "SET_ADDRESS_FIELD"
const reducer = (state, action) => {
    switch (action.type) {
        case "SET_DATA": return { ...action.payload }
        case SET_FIELD: return {
            ...state,
            [action.field]: action.value
        }
        case SET_ADDRESS_FIELD: return {
            ...state,
            address: {
                ...state.address,
                [action.field]: action.value
            }
        }
        case "SET_ADDRESS": return {
            ...state,
            address: action.address
        }
        default: return { ...state }
    }
}
const actions = {
    setField: (field, value) => {
        return { type: SET_FIELD, field, value }
    },
    setAddressField: (field, value) => {
        return {
            type: SET_ADDRESS_FIELD,
            field,
            value
        }
    },
    setAddress: (address) => {
        return {
            type: "SET_ADDRESS",
            address
        }
    },
    setData: (data) => {
        console.log("SET_DATA", data)
        return {
            type: "SET_DATA",
            payload: data
        }
    }
}
export {
    initialState,
    reducer,
    actions
}