import React from "react";

const SearchIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.84442" cy="7.84442" r="5.99237" stroke="#787878" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.0122 12.3232L14.3616 14.6665" stroke="#787878" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default SearchIcon