import * as React from "react"
const ProfileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18.601 7.291c0 2.937-2.517 5.292-5.66 5.292-3.14 0-5.66-2.355-5.66-5.292S9.802 2 12.942 2c3.143 0 5.66 2.354 5.66 5.291ZM12.941 22c-4.637 0-8.552-.705-8.552-3.425 0-2.721 3.94-3.401 8.552-3.401 4.639 0 8.553.705 8.553 3.425 0 2.721-3.94 3.401-8.553 3.401Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ProfileIcon