import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import AxiosInstance from "../services/AxiosInstance";
// import MySharedWorker from '../worker';
const DashboardLayout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        // AxiosInstance.interceptors.response.use(res => {
        //     return res
        // },
        //     (error) => {
        //         console.log("E", error)
        //         if (error.detail === "Token expired") {
        //             navigate("/")
        //         }
        //         if (error?.response) {
        //             const { data } = error.response;

        //             return Promise.reject(data)
        //         } else {
                  
        //             let data = { status: error?.message || error?.detail };
        //             console.log("llllllllllllllllllllllllllll",data)
        //             if(data.status){
        //                 if (data.status?.indexOf("timeout") !== -1) {
        //                     data.status = "No internet connection"
        //                 }
        //                 if (data.status?.indexOf("Network") !== -1) {
        //                     data.status = "No internet connection"
        //                 }
        //             }
                   
                   
        //             return Promise.reject(data)
        //         }
        //     }

        // )
    }, [])
    return (
        <Grid container>
            <TopBar />
            <Grid sx={{ padding: '24px', width: '100%' }}>
                <Outlet />
            </Grid>

        </Grid>
    )
}
const TopBar = () => {

    return (
        <Grid container sx={{ height: 60, backgroundColor: "white", justifyContent: 'space-between', paddingRight: '10px' }}>
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid className="logo" sx={{ width: 150, height: 55 }} />
            </Grid>
            <Grid sx={{ alignItems: 'center', display: 'flex' }}>
                <ProfileDropdown />
            </Grid>
        </Grid>
    )
}
export default DashboardLayout